.h1{
    margin: 3em;
}

.gallery{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count:3;
    padding: 0 12px;
    gap: 12px;
}

.pics{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    margin-bottom: 12px;
}

.pics:hover{
    filter: opacity(.8);
}

/*
@media(max-width: 991px){
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count:2;
    }
}

@media(max-width: 600px){
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width:100%;
    }
}
*/