.Image2 {
  background-image: url("../assets/images/me.png");

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  position: relative;
  min-height: 40%;
  min-width: 40%;
}

.Image1 {
  background-image: url("../assets/images/moon.png");

  background-size: contain;
  background-repeat: no-repeat;
  right: 0;

  position: absolute;

  min-height: 20%;
  min-width: 20%;
}

body {
  font-family: 'IBM Plex Serif', serif;
  margin: 0;
  padding: 0;
  background-color: #FFF8E7;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  background-color: #FFF8E7;
}